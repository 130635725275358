import * as actionTypes from '../../constants/actionTypes';
import { ASC_LOWERCASE } from '../../constants/users';

const initialState = {
  search: '',
  sortBy: [
    { column: 'firstName', order: ASC_LOWERCASE },
    { column: 'lastName', order: ASC_LOWERCASE },
    { column: 'username', order: ASC_LOWERCASE },
  ],
  pagination: {
    page: 1,
    itemsAmount: 10,
  },
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialState.pagination };
    case actionTypes.SET_USER_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_USER_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default users;
