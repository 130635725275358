import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  search: '',
  sortBy: { column: 'fullName', order: 'asc' },
  pagination: {
    page: 1,
    itemsAmount: 10,
  },
};

const team = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TEAM_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialState.pagination };
    case actionTypes.SET_TEAM_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_TEAM_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default team;
