import { Userpilot } from 'userpilot';
import { getUserData } from '@uptime/shared/utils/general';

const ENV = 'production';
if (process.env.REACT_APP_ENV === ENV) {
  Userpilot.initialize('NX-de9c5e9e');
}

type Props = {
  userId: string;
  team: string;
  profile: any;
  businessProfile: any;
};

export const initialize = ({ userId, team, profile, businessProfile }: Props) => {
  if (process.env.REACT_APP_ENV !== ENV) return;

  const { businessName, userFullName } = getUserData({ profile, businessProfile });

  Userpilot.identify(userId, {
    name: userFullName,
    email: profile?.baseUser?.email,
    company: {
      id: profile?.accountId,
      name: businessName,
    },
    team,
  });
};

export const reload = () => {
  if (process.env.REACT_APP_ENV !== ENV) return;

  Userpilot.reload();
};
