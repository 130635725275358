import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';

import muiTheme from '@uptime/shared/theme/muiTheme';
import ThemedSnackbar from '@uptime/shared/theme/ThemedSnackbar';
import { CognitoProvider } from '@uptime/shared/providers/CognitoProvider';
import { COGNITO_ENV_PORTAL } from '@uptime/shared/constants/cognito';

import { client } from './graphql/apolloClient';
import { persistor, store } from './store';
import { ThirdPartyIntegrationsProvider } from './providers/ThirdPartyIntegrationsProvider';
import PermissionsProvider from './providers/PermissionsProvider';
import App from './App';
import './index.css';

// @ts-ignore
window.store = store;

ReactDOM.render(
  // @ts-ignore
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* @ts-ignore */}
      <BrowserRouter>
        <CognitoProvider cognitoEnv={COGNITO_ENV_PORTAL}>
          <ApolloProvider client={client}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              Components={{
                success: ThemedSnackbar,
                error: ThemedSnackbar,
                warning: ThemedSnackbar,
                info: ThemedSnackbar,
              }}
            >
              <ThemeProvider theme={muiTheme}>
                <ThirdPartyIntegrationsProvider>
                  <PermissionsProvider>
                    <App />
                  </PermissionsProvider>
                </ThirdPartyIntegrationsProvider>
              </ThemeProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </CognitoProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
