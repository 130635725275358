import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import WrapLink from '@uptime/shared/components/WrapLink';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const MenuItemExpandable = ({ open, items, title, pathname, Icon, handleClick }) => {
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout={150} unmountOnExit>
        <List>
          {items.map((menu) => (
            <ListItem
              component={WrapLink}
              to={menu.url}
              key={menu.id}
              selected={pathname === menu.url}
              button
              style={{ paddingLeft: 27 }}
            >
              {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
              <ListItemText primary={menu.primary} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default MenuItemExpandable;
