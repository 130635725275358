import { useState } from 'react';
import { menuItems, analyticsItems, settingsItems } from '../listItems';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Assessment from '@mui/icons-material/Assessment';
import Settings from '@mui/icons-material/Settings';

import WrapLink from '@uptime/shared/components/WrapLink';

import MenuItemExpandable from './MenuItemExpandable';

const Menu = () => {
  const { pathname } = useLocation();
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  const handleClickAnalytics = () => {
    setOpenAnalytics(!openAnalytics);
  };
  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <div>
      {menuItems.map((menu) => (
        <ListItem
          component={WrapLink}
          to={menu.url}
          key={menu.id}
          selected={pathname.includes(menu.url)}
          button
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.primary} />
        </ListItem>
      ))}
      <MenuItemExpandable
        key="Analytics"
        open={openAnalytics}
        items={analyticsItems}
        pathname={pathname}
        title="Analytics"
        Icon={Assessment}
        handleClick={handleClickAnalytics}
      />
      <MenuItemExpandable
        key="Settings"
        open={openSettings}
        items={settingsItems}
        pathname={pathname}
        title="Settings"
        Icon={Settings}
        handleClick={handleClickSettings}
      />
    </div>
  );
};

export default Menu;
