import { useDispatch, useSelector } from 'react-redux';

import { useCognito } from '@uptime/shared/providers/CognitoProvider';

import {
  getToken,
  getUserName,
  getProfile,
  getUserType,
  getBusinessProfile,
} from '../../store/selectors/auth';
import { signOut } from '../../store/actions/auth';

const useAuth = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const userName = useSelector(getUserName);
  const userType = useSelector(getUserType);
  const profile = useSelector(getProfile);
  const businessProfile = useSelector(getBusinessProfile);
  const { isAuthorized } = useCognito();
  const { userId, accountId } = token || {};
  const isPartnerTop = userId === accountId;

  return {
    token,
    userName,
    userType,
    userId: parseInt(userId),
    accountId: parseInt(accountId),
    isPartnerTop,
    profile,
    businessProfile,
    isLoggedIn: isAuthorized,
    onSignOut: () => dispatch(signOut()),
  };
};

export default useAuth;
