import { ASCENDING_ORDER } from '../../constants/users';
import * as actionTypes from '../../constants/actionTypes';
import { FILTER_MAP } from '../../constants/workOrders';

const initialState = {
  search: '',
  isLoading: false,
  error: undefined,
  sortBy: { column: 'jobId', order: ASCENDING_ORDER.toLowerCase() },
  pagination: {
    page: 1,
    itemsAmount: 10,
  },
  makeId: undefined,
  categoryId: undefined,
  modelId: undefined,
  facilityId: undefined,
  typeId: undefined,
  areaId: undefined,
  statusIds: undefined,
};

const flow = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FLOW_SORT_BY:
      return { ...state, sortBy: action.payload.sortBy };
    case actionTypes.SET_FLOW_SEARCH:
      return { ...state, search: action.payload.search, pagination: initialState.pagination };
    case actionTypes.SET_FLOW_PAGINATION:
      return { ...state, pagination: action.payload.pagination };
    case actionTypes.SET_FLOW_WO_TYPE_ID:
      return { ...state, typeId: action.payload.typeId };
    case actionTypes.SET_FLOW_WO_AREA_ID:
      return { ...state, areaId: action.payload.areaId };
    case actionTypes.SET_FLOW_WO_STATUSES:
      const { statusIds } = action.payload;
      const ids = Boolean(statusIds) ? statusIds.map((status) => status.value) : null;
      return { ...state, statusIds: ids };
    case actionTypes.SET_FLOW_MAKE:
    case actionTypes.SET_FLOW_CATEGORY:
    case actionTypes.SET_FLOW_MODEL:
      const { dataList } = action.payload;
      const idValue = Boolean(dataList) ? dataList[0]?.value : dataList;
      const keyName = FILTER_MAP[action.type];

      return {
        ...state,
        [keyName]: idValue,
      };
    case actionTypes.SET_FLOW_FACILITY_ID:
      return {
        ...state,
        facilityId: action.payload.facilityId,
      };
    case actionTypes.CLEAR_FLOW_FILTERS:
      return {
        ...state,
        makeId: null,
        categoryId: null,
        modelId: null,
        facilityId: null,
        typeId: null,
        statusIds: null,
        areaId: null,
      };
    case actionTypes.AUTH_SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export default flow;
