import * as actionTypes from '../../constants/actionTypes';

const getPayloadFromToken = (token) => {
  const encodedPayload = token.split('.')[1];
  return JSON.parse(atob(encodedPayload));
};

const initialState = {
  isLoggedIn: false,
  token: undefined,
  loading: false,
  error: undefined,
  profile: undefined,
  businessProfile: undefined,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SET_TOKEN:
      return {
        ...state,
        token: getPayloadFromToken(action.payload.token),
        isLoggedIn: true,
      };
    case actionTypes.AUTH_SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case actionTypes.AUTH_SET_BUSINESS_PROFILE:
      return {
        ...state,
        businessProfile: action.payload,
      };
    case actionTypes.AUTH_SIGN_OUT:
      return { ...state, token: undefined, isLoggedIn: false };
    case actionTypes.AUTH_SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case actionTypes.AUTH_SET_ERROR:
      return { ...state, error: action.payload.error };
    default:
      return state;
  }
};

export default auth;
