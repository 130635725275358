export const AUTH_SET_LOADING = 'AUTH/SET_LOADING';
export const AUTH_SET_TOKEN = 'AUTH/SET_TOKEN';
export const AUTH_SIGN_OUT = 'AUTH/SIGN_OUT';
export const AUTH_SET_ERROR = 'AUTH/SET_ERROR';
export const AUTH_SET_PROFILE = 'AUTH/SET_PROFILE';
export const AUTH_SET_BUSINESS_PROFILE = 'AUTH/SET_BUSINESS_PROFILE';

export const SET_USER_SEARCH = 'USER/SET_USER_SEARCH';
export const SET_USER_SORT_BY = 'USER/SET_USER_SORT_BY';
export const SET_USER_PAGINATION = 'USER/SET_USER_PAGINATION';

export const SET_TEAM_SEARCH = 'TEAM/SET_TEAM_SEARCH';
export const SET_TEAM_SORT_BY = 'TEAM/SET_TEAM_SORT_BY';
export const SET_TEAM_PAGINATION = 'TEAM/SET_TEAM_PAGINATION';

export const SET_TEAM_SET_SEARCH = 'TEAM/SET_TEAM_SET_SEARCH';
export const SET_TEAM_SET_SORT_BY = 'TEAM/SET_TEAM_SET_SORT_BY';
export const SET_TEAM_SET_PAGINATION = 'TEAM/SET_TEAM_SET_PAGINATION';

export const SET_FACILITY_SEARCH = 'FACILITY/SET_FACILITY_SEARCH';
export const SET_FACILITY_SORT_BY = 'FACILITY/SET_FACILITY_SORT_BY';
export const SET_FACILITY_USER_ID = 'FACILITY/SET_FACILITY_USER_ID';
export const SET_FACILITY_CONTACT_ID = 'FACILITY/SET_FACILITY_CONTACT_ID';
export const CLEAR_FACILITY_FILTERS = 'FACILITY/CLEAR_FACILITY_FILTERS';
export const DELETE_FACILITY_IN_PROGRESS = 'FACILITY/DELETE_FACILITY_IN_PROGRESS';
export const DELETE_FACILITY_SUCCEED = 'FACILITY/DELETE_FACILITY_SUCCEED';
export const DELETE_FACILITY_ERROR = 'FACILITY/DELETE_FACILITY_ERROR';

export const SET_FLOW_SORT_BY = 'FLOW/SET_FLOW_SORT_BY';
export const SET_FLOW_SEARCH = 'FLOW/SET_FLOW_SEARCH';
export const SET_FLOW_PAGINATION = 'FLOW/SET_FLOW_PAGINATION';

export const SET_FLOW_MAKE = 'FLOW/SET_FLOW_MAKE';
export const SET_FLOW_MODEL = 'FLOW/SET_FLOW_MODEL';
export const SET_FLOW_CATEGORY = 'FLOW/SET_FLOW_CATEGORY';
export const SET_FLOW_FACILITY_ID = 'FLOW/SET_FLOW_FACILITY_ID';
export const SET_FLOW_WO_TYPE_ID = 'FLOW/SET_FLOW_WO_TYPE_ID';
export const SET_FLOW_WO_AREA_ID = 'FLOW/SET_FLOW_WO_AREA_ID';
export const SET_FLOW_WO_STATUSES = 'FLOW/SET_FLOW_WO_STATUSES';
export const CLEAR_FLOW_FILTERS = 'FLOW/CLEAR_FLOW_FILTERS';

export const SET_DEVICES_SORT_BY = 'DEVICES/SET_DEVICES_SORT_BY';
export const SET_DEVICES_SEARCH = 'DEVICES/SET_DEVICES_SEARCH';
export const SET_DEVICES_MAKES = 'DEVICES/SET_DEVICES_MAKES';
export const SET_DEVICES_CATEGORIES = 'DEVICES/SET_DEVICES_CATEGORIES';
export const SET_DEVICES_MODELS = 'DEVICES/SET_DEVICES_MODELS';
export const SET_DEVICES_USER_ID = 'DEVICES/SET_DEVICES_USER_ID';
export const SET_DEVICES_FACILITY_ID = 'DEVICES/SET_DEVICES_FACILITY_ID';
export const CLEAR_DEVICE_FILTERS = 'DEVICES/CLEAR_DEVICE_FILTERS';
