import { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Mode } from '@uptime/shared/components/Login/CognitoLogin.types';
import CognitoLogin from '@uptime/shared/components/Login/CognitoLogin';
import { COGNITO_ENV_PORTAL } from '@uptime/shared/constants/cognito';
import { setToken } from './store/actions/auth';
import Layout from './pages/Layout';

import PrivateRoute from './common/components/PrivateRoute';
import './App.css';

const Users = lazy(() => import('./pages/Users'));
const Facilities = lazy(() => import('./pages/Facilities'));
const FacilitiesPage = lazy(() => import('./pages/Facilities/partials/FacilitiesPage'));
const Devices = lazy(() => import('./pages/Devices'));
const DeviceView = lazy(() => import('./containers/Devices/DeviceViewContainer'));
const WorkOrders = lazy(() => import('./pages/WorkOrders'));
const WorkOrderDevices = lazy(() => import('./pages/WorkOrders/partials/WorkOrderDevices'));
const DashboardAnalyticsView = lazy(() => import('./pages/Analytics'));
const SettingsView = lazy(() => import('./pages/Settings'));

function App() {
  return (
    <Switch>
      <Route path={`/${Mode.FederatedSignIn}`}>
        <CognitoLogin mode={Mode.FederatedSignIn} />
      </Route>
      <Route path={`/${Mode.FederatedSignOut}`} exact>
        <CognitoLogin mode={Mode.FederatedSignOut} />
      </Route>
      <Route path={`/${Mode.Callback}`} exact>
        <CognitoLogin mode={Mode.Callback} cognitoEnv={COGNITO_ENV_PORTAL} setToken={setToken} />
      </Route>
      <Route path={`/${Mode.Activation}`} exact>
        <CognitoLogin
          mode={Mode.Activation}
          cognitoEnv={COGNITO_ENV_PORTAL}
          setToken={setToken}
          welcomeText="UptimePortal"
        />
      </Route>
      <Layout>
        <Suspense
          fallback={
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={16} color="primary" />
            </Box>
          }
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Switch>
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/facilities/view/:facilityId" component={FacilitiesPage} />
              <PrivateRoute path="/facilities" component={Facilities} />
              <PrivateRoute path="/devices" component={Devices} exact />
              <PrivateRoute path="/devices/view/:deviceId" component={DeviceView} />
              <PrivateRoute path="/devices" component={Devices} />
              <PrivateRoute path="/work-orders/areas" component={WorkOrders} />
              <PrivateRoute path="/work-orders" component={WorkOrderDevices} />
              <PrivateRoute path="/analytics/:dashboardID" component={DashboardAnalyticsView} />
              <PrivateRoute path="/settings" component={SettingsView} />
              <Redirect to="/users" />
            </Switch>
          </LocalizationProvider>
        </Suspense>
      </Layout>
    </Switch>
  );
}

export default App;
