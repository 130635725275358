import React from 'react';
import { nanoid } from 'nanoid';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WrenchIcon from '@mui/icons-material/Build';
import PenIcon from '@mui/icons-material/Create';
import UsersIcon from '@mui/icons-material/People';
import TaskIcon from '@mui/icons-material/PlaylistAddCheck';
import LockResetIcon from '@mui/icons-material/LockReset';

export const menuItems = [
  {
    id: nanoid(),
    icon: <PeopleIcon />,
    primary: 'Users',
    url: '/users',
  },
  {
    id: nanoid(),
    icon: <BusinessIcon />,
    primary: 'Facilities',
    url: '/facilities',
  },
  {
    id: nanoid(),
    icon: <DevicesIcon />,
    primary: 'Devices',
    url: '/devices/',
  },
  {
    id: nanoid(),
    icon: <AssignmentIcon />,
    primary: 'Service Requests',
    url: '/work-orders',
  },
];

export const analyticsItems = [
  {
    id: nanoid(),
    icon: <TaskIcon />,
    primary: 'Activities',
    url: '/analytics/2k1L0jv1yJ',
  },
  {
    id: nanoid(),
    icon: <WrenchIcon />,
    primary: 'Service Requests',
    url: '/analytics/MjAPZ5Px2d',
  },
  {
    id: nanoid(),
    icon: <DevicesIcon />,
    primary: 'Devices',
    url: '/analytics/GnAkzGz13l',
  },
  {
    id: nanoid(),
    icon: <BusinessIcon />,
    primary: 'Facilities',
    url: '/analytics/OVxbPJrADm',
  },
  {
    id: nanoid(),
    icon: <TrendingUpIcon />,
    primary: 'Financials',
    url: '/analytics/aXxpymb1qP',
  },
  {
    id: nanoid(),
    icon: <PenIcon />,
    primary: 'Warranty & Contracts',
    url: '/analytics/am1DzVWYwO',
  },
  {
    id: nanoid(),
    icon: <UsersIcon />,
    primary: 'Users',
    url: '/analytics/yWYEVWzxEq',
  },
];

export const settingsItems = [
  {
    id: nanoid(),
    icon: <LockResetIcon />,
    primary: 'Change Password',
    url: '/settings/change-password',
  },
  // TODO: this page will be implemented in v2
  // {
  //   id: nanoid(),
  //   primary: 'Business',
  //   url: '/settings/business',
  // },
  {
    id: nanoid(),
    icon: <UsersIcon />,
    primary: 'Team',
    url: '/settings/team',
  },
];
