import qs from 'query-string';

import * as actionTypes from '../../constants/actionTypes';
import { persistor } from '../index';

export const setLoading = (loading) => ({
  type: actionTypes.AUTH_SET_LOADING,
  payload: { loading },
});

export const setToken = (payload) => ({
  type: actionTypes.AUTH_SET_TOKEN,
  payload,
});

export const setProfile = (payload) => ({
  type: actionTypes.AUTH_SET_PROFILE,
  payload,
});

export const setBusinessProfile = (payload) => ({
  type: actionTypes.AUTH_SET_BUSINESS_PROFILE,
  payload,
});

export const setAuthError = (error) => ({
  type: actionTypes.AUTH_SET_ERROR,
  payload: { error },
});

export const clearError = () => ({
  type: actionTypes.AUTH_SET_ERROR,
  payload: { error: undefined },
});

export const signOut = () => (dispatch) => {
  window.location.href = qs.stringifyUrl({
    url: `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout`,
    query: {
      client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
      logout_uri: `${window.location.origin}/signout`,
    },
  });

  dispatch({
    type: actionTypes.AUTH_SIGN_OUT,
  });
  persistor.purge();
};
