import { combineReducers } from 'redux';

import flow from './flow';
import auth from './auth';
import users from './users';
import devices from './devices';
import facilities from './facilities';
import team from './team';

const rootReducer = combineReducers({
  auth,
  flow,
  users,
  devices,
  facilities,
  team,
});

export default rootReducer;
