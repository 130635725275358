import React, { createContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useOnUpdate } from '@uptime/shared/hooks';
import { TEAMS } from '@uptime/shared/constants/users';

import * as UserPilot from '../services/userpilot';
import useAuth from '../common/hooks/useAuth';

type Props = {
  children: React.ReactNode;
};

const ThirdPartyIntegrationsContext = createContext({});

export const ThirdPartyIntegrationsProvider = ({ children }: Props) => {
  const ref = useRef(false);
  const location = useLocation();
  const { profile, businessProfile, userId, isPartnerTop } = useAuth();
  const team = isPartnerTop ? TEAMS.PARTNER_ADMINISTRATOR : TEAMS.PARTNER_USER;

  useOnUpdate(() => {
    if (!profile) return;

    UserPilot.initialize({ userId: `${userId}`, profile, team, businessProfile });

    ref.current = true;
  }, [profile]);

  useOnUpdate(() => {
    if (!ref.current) return;

    UserPilot.reload();
  }, [location]);

  return (
    <ThirdPartyIntegrationsContext.Provider value={{}}>{children}</ThirdPartyIntegrationsContext.Provider>
  );
};
