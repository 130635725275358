import { gql } from '@apollo/client';

export const partnerUserList = gql`
  query partnerUserList(
    $pagination: PaginationInput
    $sortBy: [UserForPartnerSortInput!]
    $searchBy: String
    $showPartnerTeam: Boolean
    $showFacilityTop: Boolean
  ) @api(name: "user") {
    partnerUserList(
      pagination: $pagination
      sortBy: $sortBy
      searchBy: $searchBy
      showPartnerTeam: $showPartnerTeam
      showFacilityTop: $showFacilityTop
    ) {
      hits {
        id
        firstName
        lastName
        username
        businessName
        teamTitle
        planTitle
      }
      itemsCount
      currentPage
      pageCount
    }
  }
`;

export const getUserProfile = gql`
  query fetchProfile($userId: Int!) @api(name: "user") {
    userData: getProfile(userId: $userId) {
      firstName
      lastName
      accountId
      baseUser {
        email
      }
      isInviteEmailSent
      userGroupId
    }
  }
`;

export const getBusinessProfile = gql`
  query getBusinessProfile($accountId: Int!) @api(name: "user") {
    getBusinessProfile(accountId: $accountId) {
      accountId
      name
      email
      phone
    }
  }
`;

export const getProfile = gql`
  query getUserProfile($userId: Int!) @api(name: "user") {
    profile: getUserProfile(userId: $userId) {
      firstName
      lastName
      mobile
      email
    }
  }
`;

export const whetherUserExists = gql`
  query whetherUserExists($email: String!, $userId: Int) @api(name: "user") {
    isUserExists: whetherUserExists(email: $email, userId: $userId)
  }
`;

export const getTeamList = gql`
  query groupList(
    $pagination: PaginationInput
    $sortBy: [GroupSortInput!]
    $searchBy: String
    $filter: GroupFilterInput
  ) @api(name: "user") {
    groupList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        name
        description
        accountId
        businessProfile {
          name
        }
        members {
          userId
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getTeam = gql`
  query group($id: Int!) @api(name: "user") {
    group(id: $id) {
      id
      name
      description
      accountId
      businessProfile {
        name
      }
      members {
        userId
      }
    }
  }
`;

export const isGroupNameExist = gql`
  query isGroupNameExist($name: String!, $accountId: Int!) @api(name: "user") {
    isGroupNameExist(name: $name, accountId: $accountId)
  }
`;

export const getTeamSetList = gql`
  query groupSetList(
    $pagination: PaginationInput
    $sortBy: [GroupSetSortInput!]
    $searchBy: String
    $filter: GroupSetFilterInput
  ) @api(name: "user") {
    groupSetList(pagination: $pagination, sortBy: $sortBy, searchBy: $searchBy, filter: $filter) {
      hits {
        id
        name
        accountId
        businessProfile {
          name
        }
        groups {
          groupId
          group {
            name
          }
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getTeamSet = gql`
  query groupSet($id: Int!) @api(name: "user") {
    groupSet(id: $id) {
      id
      name
      accountId
      businessProfile {
        name
      }
      groups {
        groupId
      }
    }
  }
`;

export const isGroupSetNameExist = gql`
  query isGroupSetNameExist($name: String!, $accountId: Int!) @api(name: "user") {
    isGroupSetNameExist(name: $name, accountId: $accountId)
  }
`;

export const getWhiteLabel = gql`
  query getWhiteLabel($accountId: Int!) @api(name: "user") {
    whiteLabel: getWhiteLabel(accountId: $accountId) {
      accountId
      name
      email
      address
      city
      state
      postal
      site
      description
      financeUrl
      colors
      logoUrl
    }
  }
`;

export const getStaffTeams = gql`
  query getStaffTeams($accountId: Int!) @api(name: "user") {
    getStaffTeams(accountId: $accountId) {
      id
      alias
      title
    }
  }
`;

export const getUserFeatures = gql`
  query getUserFeatures @api(name: "user") {
    getUserFeatures
  }
`;
