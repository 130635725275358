export const USERS_LIST_HEADER = [
  { id: 'fullName', isSortable: true, label: 'Full Name', isVisible: true },
  { id: 'username', isSortable: true, label: 'Email', isVisible: true },
  { id: 'businessName', isSortable: true, label: 'Company', isVisible: true },
  { id: 'plan', isSortable: true, label: 'Plan', isVisible: true },
  { id: 'team', isSortable: true, label: 'Access Team', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const TEAM_LIST_HEADER = [
  { id: 'fullName', isSortable: true, label: 'Full Name', isVisible: true },
  { id: 'username', isSortable: true, label: 'Email', isVisible: true },
  { id: 'plan', isSortable: true, label: 'Plan', isVisible: true },
  { id: 'team', isSortable: true, label: 'Access Team', isVisible: true },
  {
    id: 'actions',
    isSortable: false,
    label: '',
    isVisible: true,
    style: { minWidth: 'auto', paddingRight: 0 },
  },
];

export const ASCENDING_ORDER = 'ASC';
export const DESCENDING_ORDER = 'DESC';

export const ASC_LOWERCASE = 'asc';
export const DESC_LOWERCASE = 'desc';

export const USER_TYPES = {
  FACILITY: 'facility',
  TECHNICIAN: 'repairer',
};

export const SEND_INVITE_EMAIL_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
